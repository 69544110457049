export const seo = {
  url: 'transport-rodzaje',
  title: {
    pl: 'Typy i Rodzaje Transportu | Usługi Transportowe',
    en: 'Types and Kinds of Transport | Transportation services',
  },
  desc: {
    pl: 'Dowiedz się więcej o rodzajach transportu. Sprawdź najbardziej popularne usługi w transporcie i wybierz odpowiednią dla Twojej działalności.',
    en: 'Find out more about the modes of transport. Check out the most popular transport services and choose the one that suits your business.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'jakie są rodzaje transportu',
    'typy transportu',
    'usługi transportowe',
  ],
}

export const intro = {
  title: {
    pl: 'Usługi Transportowe',
    en: 'Usługi Transportowe',
  },
  desc: {
    pl: 'Dowiedz się więcej o rodzajach transportu. Sprawdź najbardziej popularne usługi w transporcie i wybierz odpowiednią dla Twojej działalności.',
    en: 'Find out more about the modes of transport. Check out the most popular transport services and choose the one that suits your business.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Rodzaje transportu',
    en: 'Transport types',
  },
  texts: [
    {
      pl: '<span>Omida Logistics to firma transportowa posiadająca <strong>szeroką gamę produktów</strong> i usług transportowych, <strong>dedykowana</strong> poszczególnym gałęziom gospodarki oraz <strong>wyspecjalizowanym jednostkom</strong>. Realizujemy różne formy transportu, a szczegóły dotyczące każdej z form przedstawiamy w produktach szczegółowo opisanych poniżej.</span>',
      en: '<span>Omida Logistics is a transport company with a <strong> wide range of products</strong> and transport services, <strong>dedicated to individual branches</strong> of the economy and <strong>specialized units</strong>. We offer various forms of transport, and details of each form are presented in the products detailed below.</span>',
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
  },
  link: '/transport/',
}
