import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, buttonBack } from '../content/transport-rodzaje'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import { sCenteredSpaced } from '../style'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Button from '../components/Button'

const TransportRodzaje = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image, children, imageIntermodal, imagePaperliner } =
    useStaticQuery(graphql`
      {
        image: file(
          absolutePath: { regex: "/images/intros/transport-rodzaje.jpg/" }
        ) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [WEBP, AVIF]
            )
          }
        }
        children: allContentfulTransportRodzaj(sort: { fields: name }) {
          nodes {
            name
            slug
            node_locale
            image {
              gatsbyImageData(width: 480, quality: 80, formats: [WEBP, AVIF])
            }
          }
        }
        imageIntermodal: file(
          absolutePath: { regex: "/images/intros/transport-intermodalny.jpeg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [WEBP, AVIF]
            )
          }
        }
        imagePaperliner: file(
          absolutePath: { regex: "/images/intros/paperliner.jpeg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [WEBP, AVIF]
            )
          }
        }
      }
    `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  const linksMerged = [
    {
      slug: 'paperliner',
      name: 'Paperliner',
      image: imagePaperliner,
    },
    {
      slug: 'transport-intermodalny',
      name: 'Transport Intermodalny',
      image: imageIntermodal,
    },
  ].concat(links)

  const buttonSecond = {
    text: {
      pl: 'Zleć transport',
      en: 'Quote transport',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/transport-rodzaje.jpg'}
        url={seo.url}
      />
      <Intro data={{ ...intro, buttonSecond }} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Tiles data={linksMerged} />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

export default TransportRodzaje
